import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './NavMenu.css';
import { useScroll } from "./useScroll";
const NavMenu = () => {
    const [isLoged, setCollapsed] = useState(false);
    const [isClosed, setClosed] = useState(false)
    const scrollY = useScroll();
    const OpenSidebar = () => {
        setClosed(!isClosed)
    }

    
     
    return (
        <header className="my_header">
            
            <div className="waren-contact">
                <div className="Contact-info gap-3">
                    <div className="phone gap-2">
                        <i class='bx bxs-phone'></i>
                        <div class="number">
                            <img src={process.env.PUBLIC_URL + '/img/germany.png'} alt="germany flag" />
                            <span>0157 365 80 475</span>
                        </div>
                    </div>
                    <div className="email gap-2">
                        <i class='bx bxs-envelope'></i>
                        <span>ceysaas@gmail.com</span>
                    </div>
                </div>

                
                  
         </div>
        
           
            <nav className="navbare contain my_nav ">
                <div className="myContainer">
                    <NavLink to="/"><img alt="logo" className="logo" src={process.env.PUBLIC_URL + "/img/8f399e32c26846639cbf4b2038257cbf-removebg-preview.png"} />
                    </NavLink>  

                    <div className="Navslink">
                        <NavLink  to="/"><i class='bx bx-home-alt-2'></i></NavLink>
                        <NavLink to="/Uberdachung"> 
                        Terrassenüberdachung
                        </NavLink> 
                         
                        <NavLink to="/Treppen">
                        Treppengeländer Glas
                        </NavLink> 
                        <NavLink to="/Solaren">
                        Solarüberdachung
                        </NavLink> 
                       
                        <NavLink to="/Fotos"  >
                            Alle Projekte
                        </NavLink> 
                    </div>
                    <div className={isClosed ? "side" : "side active"}  >
                        <div class="Navsidebare">
                            <NavLink to="/"  onClick={OpenSidebar}><i class='bx bx-home-alt-2'></i></NavLink>

                            <NavLink onClick={OpenSidebar} to="/Uberdachung">  
                            Terrassenüberdachung 
                        </NavLink> 
                           
                            <NavLink onClick={OpenSidebar} to="/Treppen">
                        Treppengeländer Glas
                        </NavLink> 
                            <NavLink onClick={OpenSidebar} to="/Solaren">
                            Solarüberdachung
                        </NavLink> 
                        <NavLink to="/Fotos"  onClick={OpenSidebar} >
                            Alle Projekte
                        </NavLink> 
                          
                        </div>

                    </div>
                    <div className={isClosed ? "hamburge active" : "hamburge "} onClick={OpenSidebar }>

                        <div className="hamburger-lines" >
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                            <span className="line line3"></span>
                        </div>
                    </div>
                   
                    

                    <i class="fa-solid fa-sidebar"></i>

                </div>

            </nav>
            
        </header>
    );
};

export default NavMenu;